import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Services`}</h1>
    <p>{`The main `}<a parentName="p" {...{
        "href": "/hosted",
        "title": "hosted"
      }}>{`hosted`}</a>{` instance of the technology `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` is available — free and fully accessible to non-profit, public and academic institutions around the world — at `}<a parentName="p" {...{
        "href": "https://pol.is/home"
      }}>{`https://pol.is/home`}</a>{`.`}</p>
    <p>{``}<a parentName="p" {...{
        "href": "/pol.is",
        "title": "pol.is"
      }}>{`pol.is`}</a>{`, in combination with the freely available `}<a parentName="p" {...{
        "href": "/knowledge-base",
        "title": "knowledge base"
      }}>{`knowledge base`}</a>{`, are all an organization needs to implement and derive value from the `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` methodology.`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://pol.is/createuser"
      }}>{`create an account at pol.is and get started immediately`}</a>{`.`}</p>
    <p>{`The Computational Demoracy Project provides a number of services that support `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` implementations.`}</p>
    <h3>{`Ways we can help`}</h3>
    <h5>{`Implementation consultation & training`}</h5>
    <p>{`Building organizational competency at integrating Polis is useful because Polis can be used over time to augment decision making processes.`}</p>
    <p>{`CompDem can help by being an expert voice in the room through the duration of your Polis implementation, or by giving one-off training to get your team up to speed quickly.`}</p>
    <h5>{`Integration support`}</h5>
    <p>{`Integrating Polis is often as simple as adding an embed code to a website. For more complicated integrations involving identity, metadata, and demographic information, we can help troubleshoot issues you can't solve on your own using the `}<a parentName="p" {...{
        "href": "/knowledge-base",
        "title": "knowledge base"
      }}>{`knowledge base`}</a>{`.`}</p>
    <p>{`See: `}<a parentName="p" {...{
        "href": "/embed-code",
        "title": "embed code"
      }}>{`embed code`}</a>{`, `}<a parentName="p" {...{
        "href": "/xid",
        "title": "xid"
      }}>{`xid`}</a>{`, `}<a parentName="p" {...{
        "href": "/Identity",
        "title": "Identity"
      }}>{`Identity`}</a>{``}</p>
    <h5>{`Data analysis, analytics & reporting`}</h5>
    <p>{`In some cases the automated report is enough to explore the results of a Polis conversation, but it might only explain a small amount of the overall meaning, structure, and insight in your dataset. Additionally, you might want to examine demographic information from your sample, or analyze subtopics.`}</p>
    <p>{`CompDem can serve as your data science partner, creating custom reports and analysis to fit your needs.`}</p>
    <h5>{`Feature requests`}</h5>
    <p>{`In some cases, organizations need to fund open source developement so that they can use the methdology. For example, to add specific accessibility functionality.`}</p>
    <h5>{`End-to-end support`}</h5>
    <p>{`In addition to consulting, CompDem can step in to fill one or more of the `}<a parentName="p" {...{
        "href": "/roles",
        "title": "roles"
      }}>{`roles`}</a>{`, like `}<a parentName="p" {...{
        "href": "/facilitation",
        "title": "facilitation"
      }}>{`facilitation`}</a>{` practitioner or data scientist. Or, your organization can engage CompDem to run an entire project for you and deliver a report.`}</p>
    <h5>{`Fully hosted instances on your own hardware`}</h5>
    <p>{`If you need to own your data on your own hardware, we can help your developers get up and running.`}</p>
    <h4>{`Who we work with`}</h4>
    <p>{`Learn more about `}<a parentName="p" {...{
        "href": "/who-we-work-with",
        "title": "who we work with"
      }}>{`who we work with`}</a>{``}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      